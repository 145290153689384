









































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/PopUpBannerController'
import BannerList from '../components/BannerList/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import Tabs from '@/app/ui/components/Tabs/index.vue'
import AddCircle from '@/app/ui/assets/add_circle.vue'
import { Utils } from '@/app/infrastructures/misc'
import { EnumFilterByStatus, EnumFilterByType, paramsInterface, POPUP_BANNER_PAGINATION } from '@/app/infrastructures/misc/Constants'
import { filterOptionsInterface } from '@/app/infrastructures/misc'
import { parameterInterface } from '@/app/infrastructures/misc'

@Component({
  components: {
    BannerList,
    Button,
    TextInput,
    DropdownSelect,
    PaginationNav,
    Loading,
    AddCircle,
    Tabs,
    Modal,
  },
})
export default class PopUpBanner extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  statusOptions: Array<filterOptionsInterface> = [
    { label: this.toNormalize(EnumFilterByStatus.ALL_STATUS), value: '' },
    { label: this.toNormalize(EnumFilterByStatus.ACTIVE), value: this.toNormalize(EnumFilterByStatus.ACTIVE) },
    { label: this.toNormalize(EnumFilterByStatus.PENDING), value: this.toNormalize(EnumFilterByStatus.PENDING) },
  ]
  typeOptions: Array<filterOptionsInterface> = [
    { label: this.toNormalize(EnumFilterByType.ALL_TYPE), value: '' },
    { label: EnumFilterByType.INFO, value: EnumFilterByType.INFO.toLowerCase() },
    { label: EnumFilterByType.PROMO, value: EnumFilterByType.PROMO.toLowerCase() },
    { label: EnumFilterByType.OTHERS, value: EnumFilterByType.OTHERS.toLowerCase() },
  ]
  sortOptions: Array<filterOptionsInterface> = [
    { label: 'Latest', value: 'desc' },
    { label: 'Oldest', value: 'asc' },
  ]
  itemTabs: Array<filterOptionsInterface> = [
    { label: 'Active', value: 0 },
    { label: 'Not Active', value: 1 },
  ]
  parameters: parameterInterface = {
    page: 1,
    perPage: POPUP_BANNER_PAGINATION,
    keyword: null,
    status: this.statusOptions[0],
    type: this.typeOptions[0],
    sortBy: this.sortOptions[0],
    isActive: true,
  }
  currentTab = 0
  pageActive = 1
  pageInactive = 1

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = <Record<string, never>>this.$route.query
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: Utils.alwaysNumber(queries.perPage) || POPUP_BANNER_PAGINATION,
        keyword: queries.keyword,
        sortBy:
          this.sortOptions.find(item => item.value === queries.sortBy) ||
          this.sortOptions[0],
        status:
          this.statusOptions.find(
            item => item.value === queries.status
          ) || this.statusOptions[0],
        type:
          this.typeOptions.find(
            item => item.value === queries.type
          ) || this.typeOptions[0],
        isActive: queries.isActive === 'true' ? true : false,
      }
      if (queries.isActive === 'false') {
        this.currentTab = 1
      } else {
        this.currentTab = 0
      }
    }

    this.fetchPopUpBannerData()
  }

  private toNormalize(string: string): string {
    return Utils.toCapitalize(string.replace('_', ' '))
  }

  get params(): paramsInterface {
    return {
      ...this.parameters,
      sortBy: this.parameters.sortBy?.value,
      perPage: POPUP_BANNER_PAGINATION,
      status: this.parameters.status?.value,
      type: this.parameters.type?.value,
      isActive: this.currentTab === 0,
    }
  }

  @Watch('currentTab')
  onItemTabsChanged(val: number): void {
    if (val === 0) {
      this.statusOptions = [
        { label: this.toNormalize(EnumFilterByStatus.ALL_STATUS), value: '' },
        { label: this.toNormalize(EnumFilterByStatus.ACTIVE), value: this.toNormalize(EnumFilterByStatus.ACTIVE) },
        { label: this.toNormalize(EnumFilterByStatus.PENDING), value: this.toNormalize(EnumFilterByStatus.PENDING) },
      ]
    } else {
      this.statusOptions = [
        { label: this.toNormalize(EnumFilterByStatus.ALL_STATUS), value: '' },
        { label: this.toNormalize(EnumFilterByStatus.CANCELLED), value: this.toNormalize(EnumFilterByStatus.CANCELLED) },
        { label: this.toNormalize(EnumFilterByStatus.EXPIRED), value: this.toNormalize(EnumFilterByStatus.EXPIRED) },
      ]
    }
  }

  @Watch('parameters.keyword')
  onKeywordChanged(val: string): void {
    val === '' && this.fetchPopUpBannerData()
  }

  @Watch('params')
  onParamsChanged(val: Record<string, never>): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  private onTabsClick(tab: number): void {
    this.parameters.status = this.statusOptions[0]
    this.currentTab = tab
    if (tab === 0) {
      this.pageInactive = this.parameters.page
      this.parameters.page = this.pageActive
    } else {
      this.pageActive = this.parameters.page
      this.parameters.page = this.pageInactive
    }
    this.fetchPopUpBannerData()
  }

  private fetchPopUpBannerData(reset?: boolean): void {
    if (reset) this.parameters.page = 1
    controller.getPopUpBannerData(this.params)
  }
}
