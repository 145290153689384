















































































import dayjs from 'dayjs'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PopUpBanner } from '@/domain/entities/Banner'
import Badge from '@/app/ui/components/Badge/index.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import AddCircle from '@/app/ui/assets/add_circle.vue'
import Eye from '@/app/ui/assets/eye.vue'
import { 
  EnumFilterByStatus,
  Utils,
} from '@/app/infrastructures/misc'

@Component({
  components: {
    Badge,
    EditIcon,
    Modal,
    AddCircle,
    Eye,
  },
})
export default class BannerList extends Vue {
  @Prop() private isGuest!: boolean
  @Prop() private banner!: PopUpBanner

  get statusBadgeType(): string {
    if (this.banner.status?.toUpperCase() === EnumFilterByStatus.CANCELLED) {
      return 'error'
    } else if (
      this.banner.status?.toUpperCase() === EnumFilterByStatus.EXPIRED
    ) {
      return 'expire'
    } else if (
      this.banner.status?.toUpperCase() === EnumFilterByStatus.ACTIVE
    ) {
      return 'success'
    } else {
      return 'warning'
    }
  }

  get isActive(): boolean {
    if (
      this.banner.status?.toUpperCase() === EnumFilterByStatus.ACTIVE ||
      this.banner.status?.toUpperCase() === EnumFilterByStatus.PENDING
    ) {
      return true
    }
    return false
  }

  private formatDate(date: string) {
    return dayjs(date).format('DD MMMM YYYY[\n]HH:mm [WIB]')
  }

  private onEditBanner() {
    this.$router.push(`pop-up-banner/${this.banner.id}/edit`)
  }

  private toNormalize(text: string, strJoin = ' '): string {
    const words = text.split('_')
    words.forEach((word, i) => {
      words[i] = word[0].toUpperCase() + word.substr(1)
    })

    return words.join(strJoin)
  }

  private toCapitalize(string: string): string {
    return Utils.toCapitalize(string.replace('_', ''))
  }
}
