import {
  CreatePopUpBannerRequestInterface,
  UpdatePopUpBannerRequestInterface,
} from '@/data/payload/contracts/BannerRequest'

export class CreatePopUpBannerRequest
  implements CreatePopUpBannerRequestInterface {
  public type?: string | number
  public title?: string
  public location?: string | number
  public image?: Blob
  public url?: string
  public timer?: number
  public showTime?: string | number
  public startDate?: string
  public endDate?: string

  constructor(
    type?: string | number,
    title?: string,
    location?: string | number,
    image?: Blob,
    url?: string,
    timer?: number,
    showTime?: string | number,
    startDate?: string,
    endDate?: string,
  ) {
    this.type = type
    this.title = title
    this.location = location
    this.image = image
    this.url = url
    this.timer = timer
    this.showTime = showTime
    this.startDate = startDate
    this.endDate = endDate
  }

  public toPayload(): FormData {
    const data = new FormData()
    data.append('type', <string>this.type)
    data.append('title', <string>this.title)
    data.append('location', <string>this.location)
    data.append('image', <Blob>this.image)
    data.append('deep_link_url', <string>this.url)
    data.append('timer', <string>this.timer?.toString())
    data.append('frequency', <string>this.showTime)
    data.append('start_date', <string>this.startDate)
    data.append('end_date', <string>this.endDate)

    return data
  }
}

export class UpdatePopUpBannerRequest
  implements UpdatePopUpBannerRequestInterface {
  public type?: string | number
  public title?: string
  public location?: string | number
  public image?: Blob
  public url?: string
  public timer?: number
  public showTime?: string | number
  public startDate?: string
  public endDate?: string
  public isUpdatedImage?: boolean

  constructor(
    type?: string | number,
    title?: string,
    location?: string | number,
    image?: Blob,
    url?: string,
    timer?: number,
    showTime?: string | number,
    startDate?: string,
    endDate?: string,
    isUpdatedImage?: boolean,
  ) {
    this.type = type
    this.title = title
    this.location = location
    this.image = image
    this.url = url
    this.timer = timer
    this.showTime = showTime
    this.startDate = startDate
    this.endDate = endDate
    this.isUpdatedImage = isUpdatedImage
  }

  public toPayload(): FormData {
    const data = new FormData()
    data.append('type', <string>this.type)
    data.append('title', <string>this.title)
    data.append('location', <string>this.location)
    data.append('image', <Blob>this.image)
    data.append('deep_link_url', <string>this.url)
    data.append('timer', <string>this.timer?.toString())
    data.append('frequency', <string>this.showTime?.toString())
    data.append('start_date', <string>this.startDate)
    data.append('end_date', <string>this.endDate)
    data.append('is_updated_image', <string>String(this.isUpdatedImage))

    return data
  }
}
