import Vue from 'vue'
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { PopUpBanner, PopUpBanners } from '@/domain/entities/Banner'
import { Pagination } from '@/domain/entities/Pagination'
import { BannerPresenter } from '../presenters/BannerPresenter'
import { container } from 'tsyringe'
import {
  EventBus,
  EventBusConstants,
  paramsInterface,
  PopUpBannerForm,
  POPUP_BANNER_PAGINATION,
  Utils,
} from '@/app/infrastructures/misc'
import {
  CreatePopUpBannerRequest,
  UpdatePopUpBannerRequest,
} from '@/data/payload/api/PopUpBannerRequest'
import dayjs from 'dayjs'

export interface PopUpBannerState {
  isLoading: boolean
  popUpBannerData: Array<PopUpBanner>
  paginationData: Pagination
  popUpBannerDetail: PopUpBanner
}

@Module({ namespaced: true, store, name: 'popup-banner', dynamic: true })
class PopUpBannerController extends VuexModule implements PopUpBannerState {
  private presenter: BannerPresenter = container.resolve(BannerPresenter)
  public isLoading = false
  public popUpBannerData = [new PopUpBanner()]
  public paginationData = new Pagination(1, POPUP_BANNER_PAGINATION, 0)
  public popUpBannerDetail = new PopUpBanner()

  @Action({ rawError: true })
  public getPopUpBannerData(params: paramsInterface): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getPopUpBanners(formattedParams)
      .then(res => {
        this.setPopUpBannerData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Pop Up Banner Error',
          text:
            error?.status === 400
              ? error?.error?.message?.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getPopUpBannerDetail(id: string): void {
    this.setLoading(true)
    this.presenter
      .getPopUpBanner(id)
      .then(res => {
        this.setPopUpBannerDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Detail Pop Up Banner Error',
          text:
            error?.status === 400
              ? error?.error?.message?.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public createPopUpBanner(form: PopUpBannerForm): void {
    this.setLoading(true)
    const payload = new CreatePopUpBannerRequest(
      form.type,
      form.title,
      form.location,
      form.image,
      form.url,
      form.timer,
      form.showTime,
      dayjs(
        `${form.startDate} ${form.startTime}`,
        'YYYY-MM-DD HH:mm:ss'
      ).format(),
      dayjs(
				`${form.endDate} ${form.endTime}`,
				'YYYY-MM-DD HH:mm:ss')
			.format()
    )

    this.presenter
      .createPopUpBanner(payload)
      .then(() => {
        EventBus.$emit(EventBusConstants.CREATE_POPUP_BANNER_SUCCESS, {})
      })
      .catch(error => {
        Vue.notify({
          title: 'Create PopUp Banner Error',
          text:
            error?.status === 422
              ? error?.error?.message?.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updatePopUpBanner(form: PopUpBannerForm): void {
    this.setLoading(true)
    const payload = new UpdatePopUpBannerRequest(
      form.type,
      form.title,
      form.location,
      form.image,
      form.url,
      form.timer,
      form.showTime,
			dayjs(
        `${form.startDate} ${form.startTime}`,
        'YYYY-MM-DD HH:mm:ss'
      ).format(),
      dayjs(
				`${form.endDate} ${form.endTime}`,
				'YYYY-MM-DD HH:mm:ss')
			.format(),
      form.isUpdatedImage,
    )

		this.presenter
			.updatePopUpBanner(form.id, payload)
			.then(() => {
				EventBus.$emit(EventBusConstants.UPDATE_POPUP_BANNER_SUCCESS, {})
			})
			.catch(error => {
				Vue.notify({
					title: 'Update PopUp Banner Error',
					text:
						error?.status === 422
							? error?.error?.message?.en
							: 'Something wrong',
					type: 'error',
					duration: 5000,
				})
			})
			.finally(() => {
				this.setLoading(false)
			})
  }

  @Action({ rawError: true })
  public inactivePopUpBanner(id: string): void {
    this.setLoading(true)
    
    this.presenter
      .inactivePopUpBanner(id)
      .then(() => {
        EventBus.$emit(EventBusConstants.INACTIVE_POPUP_BANNER_SUCCESS, {})
      })
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setPopUpBannerData(bannerData: PopUpBanners) {
    this.paginationData = <Pagination>bannerData.pagination
    this.popUpBannerData = <Array<PopUpBanner>>bannerData.data
  }

  @Mutation
  private setPopUpBannerDetail(bannerDetail: PopUpBanner) {
    this.popUpBannerDetail = <PopUpBanner>bannerDetail
  }
}

export default getModule(PopUpBannerController)
